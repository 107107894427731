import type { LinkProps } from "@remix-run/react";
import { Link } from "@remix-run/react";
import { cva, type VariantProps } from "class-variance-authority";
import type { ComponentPropsWithoutRef, ReactElement } from "react";
import { cloneElement, forwardRef, useEffect, useState } from "react";
import { RiCheckFill } from "react-icons/ri";

import { Spinner } from "~/components/ui/spinner";
import { cn } from "~/utils/classnames";

export const buttonStyles = cva(
  "inline-flex shrink-0 items-center justify-center gap-2 whitespace-nowrap rounded-full border border-transparent text-center text-sm font-medium leading-tight transition duration-100 active:scale-[98%] disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variant: {
        primary: "bg-grey-900 text-white hover:bg-grey-800",
        accent: "bg-primary text-grey-900 hover:bg-primary-400",
        secondary: "bg-grey-100 text-grey-900 hover:bg-grey-200",
        outline: "border border-grey-900 text-grey-900 hover:bg-grey-800/5",
        ghost: "text-grey-900 hover:bg-grey-100",
        link: "text-grey-900 hover:underline",
        destructive: "bg-danger-500 text-white hover:bg-danger-600",
      },
      size: {
        xs: "h-6 px-3 text-xs",
        sm: "h-8 px-4",
        default: "h-10 px-6",
        lg: "h-12 px-8 text-base",
      },
      iconOnly: {
        true: "px-0",
      },
      iconPosition: {
        left: "flex-row-reverse",
        right: "flex-row",
      },
    },
    compoundVariants: [
      {
        iconOnly: true,
        size: "xs",
        className: "size-6",
      },
      {
        iconOnly: true,
        size: "sm",
        className: "size-8",
      },
      {
        iconOnly: true,
        size: "default",
        className: "size-10 text-base",
      },
      {
        iconOnly: true,
        size: "lg",
        className: "size-12",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  }
);

interface BaseProps extends VariantProps<typeof buttonStyles> {
  icon?: ReactElement | null;
  loadingState?: "idle" | "loading" | "success";
  loadingText?: string;
  successText?: string;
  disabled?: boolean;
}

export type ButtonProps = BaseProps &
  (
    | (ComponentPropsWithoutRef<"button"> & {
        as?: "button";
        to?: never;
      })
    | (LinkProps & {
        as: "link";
      })
  );

export const Button = forwardRef<any, ButtonProps>(function Button(
  {
    variant,
    size,
    icon,
    iconOnly,
    iconPosition = "left",
    loadingState: _loadingState = "idle",
    loadingText,
    successText,
    disabled,
    className,
    children,
    ...props
  },
  ref
) {
  const [loadingState, setLoadingState] = useState(_loadingState);

  useEffect(() => {
    if (_loadingState === "success") {
      setLoadingState("success");
      setTimeout(() => {
        setLoadingState("idle");
      }, 2000);
    } else {
      setLoadingState(_loadingState);
    }
  }, [_loadingState]);

  const isDisabled = loadingState === "loading" || loadingState === "success" || disabled || false;

  const finalText =
    loadingState === "loading"
      ? loadingText || children
      : loadingState === "success"
        ? successText || children
        : children;

  const finalIcon =
    loadingState === "loading" ? (
      <Spinner className="h-auto w-auto shrink-0" aria-hidden />
    ) : loadingState === "success" ? (
      <RiCheckFill className="shrink-0 text-success-600" aria-hidden />
    ) : icon ? (
      // Add shrink-0 to icon but with existing classes
      cloneElement(icon, {
        className: cn(icon.props.className, "shrink-0"),
      })
    ) : null;

  const classNameWithVariants = cn(
    buttonStyles({ variant, size, iconOnly, iconPosition, className }),
    isDisabled && "pointer-events-none cursor-default opacity-50"
  );

  if (props.as === "link") {
    const { as, ...propsRest } = props;
    return (
      <Link ref={ref} className={classNameWithVariants} aria-disabled={isDisabled} {...propsRest}>
        {iconOnly ? (
          <span className="sr-only">{finalText}</span>
        ) : (
          <span className="block leading-none">{finalText}</span>
        )}
        {finalIcon}
      </Link>
    );
  }
  const { as, ...propsRest } = props;
  return (
    <button ref={ref} className={classNameWithVariants} {...propsRest}>
      {iconOnly ? (
        <span className="sr-only">{finalText}</span>
      ) : (
        <span className="block leading-none">{finalText}</span>
      )}
      {finalIcon}
    </button>
  );
});
